import {mapGetters} from "vuex";

export default {
    props: {
        description: {
            type: String,
            default: () => ''
        },
        syllable: {
            type: String,
            default: () => ''
        },
        recommendation: {
            type: Object,
            default: () => {
            }
        },
    },
    name: "everything-about",
    computed: {
        ...mapGetters({
            product: 'card/product',
        })
    },
    methods: {
        readMore(tab, el) {
            this.$emit('more', tab)
            this.smoothScroll(el)
        },
        smoothScroll(el) {
            const myEl = document.getElementById(el)

            if (myEl === null) {
                this.$router.push({path: "/"}).then(() => {
                    const myEl = document.getElementById(el)
                    this.$smoothScroll({
                        scrollTo: myEl,
                        // hash: '#sampleHash'
                    })
                })
            } else {
                this.$smoothScroll({
                    scrollTo: myEl,
                    // hash: '#sampleHash'
                })
            }


        },
    }
}
