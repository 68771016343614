import everythingAbout from './tabs/everything-about/index.vue'
import description from './tabs/description/index.vue'
import structure from './tabs/description/index.vue'
import recommendation from './tabs/description/index.vue'
import comments from './tabs/comments/index.vue'
import comment from './components/comment-card/index.vue'
import questions from './tabs/questions/index.vue'
import slider from './components/slider/index.vue'
import product from '../../components/product/index.vue'
import accordion from './components/accordion/index.vue'
import {mapActions, mapGetters, mapMutations} from "vuex";
import {Carousel, Slide} from 'vue-carousel';

export default {
  name: "card",
  components: {
    everythingAbout,
    description,
    structure,
    recommendation,
    comment,
    comments,
    questions,
    slider,
    Carousel,
    Slide,
    product,
    accordion
  },
  data() {
    return {
      tabs: [
        {
          id: 0,
          title: 'Усе про товар'
        },
        {
          id: 1,
          title: 'Опис'
        },
        {
          id: 2,
          title: 'Склад'
        },
        {
          id: 3,
          title: 'Рекомендації'
        },
        {
          id: 4,
          title: 'Відгуки та запитання'
        },
        // {
        //   id: 5,
        //   title: 'Запитання-відповіді'
        // }
      ],
      currentTab: 1,
      selectedCount: 1,
      selectedProduct: {}
    }
  },
  watch: {
    '$route'() {
      this.fetchProduct({
        slug: this.$route.params.slug,
        category: this.$route.params.category !== 'catalog' ? this.$route.params.category : ''
      }).then(() => {
        if (this.product.productPrices.data.length)
          this.selectedProduct = this.product.productPrices.data[0]
      })
      this.fetchSameProduct(this.$route.params.slug)
    }
  },
  created() {
    if (this.$route.params.lang === 'ru') {
      this.tabs.forEach(el => {
        if (el.title === 'Опис') {
          el.title = 'Описание'
        }
        if (el.title === 'Усе про товар') {
          el.title = 'Все о товаре'
        }
        if (el.title === 'Склад') {
          el.title = 'Состав'
        }
        if (el.title === 'Рекомендації') {
          el.title = 'Рекомендации'
        }
        if (el.title === 'Відгуки та запитання') {
          el.title = 'Отзывы и вопросы'
        }

      })
    }
    this.fetchProduct({
      slug: this.$route.params.slug,
      category: this.$route.params.category !== 'catalog' ? this.$route.params.category : ''
    }).then(() => {
      if (this.product.productPrices.data.length)
        this.selectedProduct = this.product.productPrices.data[0]
    })
    this.fetchSameProduct(this.$route.params.slug)
  },
  computed: {
    ...mapGetters({
      productLoading: 'card/productLoading',
      product: 'card/product',
      sameProducts: 'card/sameProducts',
      isProfessional: 'profile/isProfessional',
      favourites: 'favorites/whichList',
    })
  },
  methods: {
    ...mapActions({
      addToFavorite: 'favorites/ADD_PRODUCT_TO_FAVOURITES',
      fetchProduct: 'card/GET_PRODUCT',
      fetchSameProduct: 'card/GET_SAME_PRODUCTS',
      removeFromFavorite: 'favorites/REMOVE_PRODUCT_IN_FAVORITE',
      getFavorites: 'favorites/GET_FAVORITES_LIST',
      // fetchSameProduct: 'card/GET_SAME_PRODUCT',

    }),
    ...mapMutations({
      addToBasket: `basket/ADD_ITEM_TO_BASKET`,
      changeFastBuyPopup: `popups/CHANGE_FAST_BUY_POPUP`,
      changeLoginPopup: 'popups/CHANGE_LOGIN_POPUP',
    }),
    changeFavorite() {
      if (this.isAuthenticated) {

        const index = this.favourites.find(e => e.productId === this.product.id)
        if (index) {
          this.removeFromFavorite(index.id).then(() => {
            this.getFavorites()
            this.favoriteSelect = false
            this.$toasted.success(this.$t('successRemoveFromFavorite'))
          })
        } else {
          this.addToFavorite({product_id: this.product.id}).then(() => {
            this.favoriteSelect = true
            this.$toasted.success(this.$t('successAddToFavorites'));
            this.getFavorites()
          })
        }
      } else {
        this.changeLoginPopup(true)
      }
    },
    changeTab(val) {
      this.currentTab = val
    },
    openFastBuy() {
      this.changeFastBuyPopup({
        show: true,
        product: {
          select_count: 1,
          select_type: this.selectedProduct,
          ...this.product
        }
      })
    },
    changeCount(type) {
      if (type === 'decrement' && this.selectedCount > 1) {
        this.selectedCount--
      } else if (type === 'increment') {
        this.selectedCount++
      }
    },
    carouselNav($event, direction) {
      const carousel = this.$refs['card-carousel'];
      carousel.advancePage(direction);
    },
    addBasket() {
      this.addToBasket({
        select_count: this.selectedCount,
        select_type: this.selectedProduct,
        ...this.product
      })
      this.$toasted.success(this.$t('successAddBasket'));
    },

  }
}
