export default {
  props: {
    description:{
      type: String,
      default: () => null
    },
    syllable:{
      type: String,
      default: () => null
    },
    recommendation: {
      type: Array,
      default: () => null
    },
  },
  computed:{
    currentContent(){
      return (this.description || this.syllable || this.recommendation)
    }
  },
  name: "description",
  methods:{

  }
}