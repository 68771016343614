import {VueSlideToggle} from "vue-slide-toggle";

export default {
  name: "accordion-card",
  props: {
    label: {
      type: String,
      default: () => ''
    },
    description:{
      type: Object,
      default: () => {}
    }

  },
  components: {
    VueSlideToggle
  },
  data() {
    return {
      open: false,
    }
  },

}