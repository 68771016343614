import questionCard from '../../components/question-card/index.vue'
import {mapActions, mapGetters, mapMutations} from "vuex";

import {maxLength, required, email} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";
export default {
  name: "questions",
  mixin: [validationMixin],
  data(){
    return{
      payload:{
        message:''
      },
      validationErrors: {},
      showForm:false,
    }
  },
  validations:{
    payload:{
      message:{
        required
      }
    }
  },
  components:{
    questionCard
  },
  computed:{
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      product:'card/product',

    }),
    messageErrors() {
      let error = [];
      if (!this.$v.payload.message.$dirty) {
        return error;
      }
      if (!this.$v.payload.message.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.message) {
        this.validationErrors.message.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    }
  },
  methods:{
    ...mapActions({
        sendQuestion:'card/SEND_QUESTION'
    }),
    ...mapMutations({
      changeLoginPopup:'popups/CHANGE_LOGIN_POPUP',
    }),
    submit(){
      if(this.isAuthenticated){
        this.$v.$touch();
        if (!this.$v.$invalid) {
          let obj = {}
          obj.product_id = this.product.id
          obj.user_question = this.payload.message
          this.sendQuestion(obj).then(()=>{
            this.payload.message = ''
            this.$v.$reset()
            this.showForm = false
            this.$toasted.success(this.$t('successQuestionSend'));
          })
        }
      }else{
        this.changeLoginPopup(true)
      }
    }
  }
}