import StarRating from 'vue-star-rating'
import {mapActions, mapGetters} from "vuex";
import {snakeCase} from "lodash";
import {maxLength, required, email} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";

export default {
  name: "comment",
  mixin: [validationMixin],
  components: {
    StarRating,
  },
  props: {
    comment: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'profile/user',
      product: 'card/product',
      isAuthenticated: 'auth/isAuthenticated',
    }),
    messageErrors() {
      let error = [];
      if (!this.$v.payload.message.$dirty) {
        return error;
      }
      if (!this.$v.payload.message.required) {
        error.push(this.$t('validationRequired'));
      }
      if (this.validationErrors.message) {
        this.validationErrors.message.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    }
  },
  validations: {
    payload: {
      message: {
        required
      }
    }
  },
  data() {
    return {
      showForm: false,
      data: null,
      showMore:false,
      payload: {
        message: '',
        productId: '',
        parentId: '',
        name: '',
        email: '',
        rating: 0
      },
      validationErrors: {}
    }
  },
  watch: {
    'showForm'() {
      this.payload.parentId = this.comment.id
      this.payload.productId = this.product.id
    }
  },
  created() {
    // let today = new Date()
    // var dd = String(today.getDate()).padStart(2, '0');
    // var mm = String(today.getMonth() + 1).padStart(2, '0');
    // var yyyy = today.getFullYear();
    // this.data = dd + '-' + mm + '-' + yyyy;
    if (this.user) {
      this.payload.email = this.user.email
      this.payload.name = this.user.name
    }
    this.payload.productId = this.product.id
  },
  methods: {
    ...mapActions({
      sendFeedback: 'card/SEND_FEEDBACK'
    }),
    transformData() {
      let obj = {}
      for (let i in this.payload) {
        obj[snakeCase(i)] = this.payload[i]
      }
      return obj
    },
    resetForm() {
      this.payload.message = ''
      this.$v.$reset()
    },
    sendAnswer() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.sendFeedback(this.transformData()).then(() => {
          this.resetForm()
          this.$toasted.success(this.$t('successSendFeedback'));
        })
      }
    }
  }
}