import comment from '../../components/comment-card/index.vue'
import question from '../../components/question-card/index.vue'
import {snakeCase} from 'lodash'
import {mapActions, mapGetters, mapMutations} from "vuex";
import StarRating from 'vue-star-rating'

import {maxLength, required, email} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";

export default {
  name: "card-comments",
  components: {
    comment,
    StarRating,
    question
  },
  mixin: [validationMixin],
  data() {
    return {
      showForm: false,
      showQuestion: false,
      question: '',
      payload: {
        name: '',
        email: '',
        message: '',
        parentId: '',
        productId: '',
        rating: 0,
        files: [],
        created_at:'43434'
      },
      validationErrors: {}
    }
  },
  validations: {
    payload: {
      email: {
        email,
      },
      message: {
        required
      }
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      product: 'card/product',
    }),
    emailErrors() {
      let error = [];
      if (!this.$v.payload.email.$dirty) {
        return error;
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('validationEmail'));
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    messageErrors() {
      let error = [];
      if (!this.$v.payload.message.$dirty) {
        return error;
      }
      if (!this.$v.payload.message.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.message) {
        this.validationErrors.message.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
  },
  created() {
    if (this.product)
      this.payload.productId = this.product.id
  },
  methods: {
    ...mapActions({
      sendFeedback: 'card/SEND_FEEDBACK',
      sendQuestion: 'card/SEND_QUESTION'
    }),
    ...mapMutations({
      changeLoginPopup:'popups/CHANGE_LOGIN_POPUP',
    }),
    handleFileChange(evt) {
      let arr = Array.from(evt.target.files)
      this.payload.files = this.payload.files.concat(arr)
    },
    removeFile(file) {
      const index = this.payload.files.findIndex(e => e.name === file.name)
      this.payload.files.splice(index, 1)
    },
    parsFormData(obj) {
      let fd = new FormData();
      for (let i in obj) {
        if (i === 'files') {
          for (let j in obj[i]) {
            fd.append(`files[${j}]`, obj[i][j]);
          }
        } else {
          fd.append(i, obj[i])
        }
      }
      return fd
    },
    transformData() {
      let obj = {}
      for (let i in this.payload) {
        obj[snakeCase(i)] = this.payload[i]
      }
      return obj
    },
    resetForm() {
      this.payload.name = ''
      this.payload.email = ''
      this.payload.rating = 0
      this.payload.message = ''
      this.payload.files = []
      this.payload.created_at=''
      this.$v.$reset()
    },
    questionSend() {

      if(this.isAuthenticated){
        this.sendQuestion({user_question: this.question, product_id: this.product.id}).then(() => {
          this.question = ''
          this.showQuestion = false
          this.$toasted.success(this.$t('successQuestionSend'))
        })
      }else{
        this.changeLoginPopup(true)
      }

    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.sendFeedback(this.parsFormData(this.transformData())).then(() => {
          this.showForm = false
          this.$toasted.success(this.$t('successFeedbackSend'));
          this.resetForm()
        })
      }
    }
  }
}
